// services/timelineCommunication/index.js
import Axios from "../../utils/axios";
import { API_URLS } from "../../utils/Constant";

export const getAllDetails = async (queryParams) => {
  try {
    const response = await Axios.get(
      API_URLS.COMMUNICATION_TIMELINE.All_DETAILS,
      {
        params: queryParams,
      }
    );
    if (response.status===200) {
      const data = response.data.data;
      const totalCount = response?.data?.totalCount
      return {data,totalCount};
    } else {
      return null;
      throw new Error(response.statusMessage);
    }
  } catch (error) {
    return null;
    console.error("Error:", error);
    throw error;
  }
};
