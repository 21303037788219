
import "../../styles/RoleUser/createRole.scss";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment/moment";
import axiosRole from '../../utils/axiosRole';
import { API_URLS, REGX  } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { ROUTE_PATH } from "../../constants/Routes";
import Breadcrumb from "../../common/BreadCrumb";


const EditRole = () => {
    const navigate= useNavigate();
    const location=useLocation();
    const [error, setError] = useState(null);
  const [permissions, setPermissions] = useState([
    {
      moduleId: 1,
      role_id:location.state["ROLE_DATA"].id,
      moduleCreate: true,
      moduleEdit: true,
      moduleView: false,
      moduleDownload: false,
      moduleAll: false,
      moduleDelete: true,
      createdAt: moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS'),
      createdBy: "Admin",
    },
    {
      moduleId: 2,
      role_id:location.state["ROLE_DATA"].id,
      moduleCreate: false,
      moduleEdit: false,
      moduleView: false,
      moduleDownload: true,
      moduleAll: false,
      moduleDelete: false,
      createdAt: moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS'),
      createdBy: "Admin",
    },
    {
      moduleId: 3,
      role_id:location.state["ROLE_DATA"].id,
      moduleCreate: false,
      moduleEdit: false,
      moduleView: false,
      moduleDownload: true,
      moduleAll: false,
      moduleDelete: false,
      createdAt:moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS'),
      createdBy: "Admin",
    },
  ]);
  
  const [formData, setFormData] = useState({
    role_id:location.state["ROLE_DATA"].id,
    role_name: location.state["ROLE_DATA"].role_name,
    role_description: location.state["ROLE_DATA"].role_description,
    updatedAt: moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS'),
    updatedBy: "Admin",
    permissionDTO: permissions,
  });
 
  const handleCheckboxChange = (module, permission) => {
    permissions[module][permission] = !permissions[module][permission];
    const modules = Object.keys(permissions[module]);
    const access = Object.values(permissions[module]);
    if (permission === "moduleAll") {
      access.map((i, j) => {
        if (typeof i === "boolean") {
          permissions[module][modules[j]] = permissions[module][permission];
        }
        return i;
      });
    }else{
      const allchecked =["moduleCreate","moduleDownload","moduleView","moduleEdit"].every((i) => {
         return permissions[module][i] === true
      });
      permissions[module].moduleAll = allchecked;
    }
    const updatedPermissions = [...permissions];
    setPermissions(updatedPermissions);
  };
  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const getPermissions=(roleId)=>{
    axiosRole.get(API_URLS.ROLE.LIST_PERMISSIONS+roleId)
    .then((response)=>{
     
      setPermissions(response?.data?.data)
    }).catch((err)=>{

    })
  }
  const validateData = () => {
    const newErrors = {};
    let isvalid = true;
    if (!formData.role_name) {
      isvalid = false;
      newErrors.role_name = "Role Name is required";
    } if (!formData.role_description) {
      newErrors.role_description = "Role Description is required";
      isvalid = false;
    }else if (!REGX.ROLE_DESC.test(formData?.role_description)) {
      newErrors.role_description = "Role Description cannot contain special characters";
      isvalid = false;}
    setError(newErrors);
    return isvalid;
  };
  const submitRole = () => {
    if(validateData()){
      const body ={
        
          role_id:formData.role_id,
          role_name: formData.role_name,
          role_description: formData.role_description,
          updatedAt: moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS'),
          updatedBy: "Admin",
          permissionDTO: permissions,
        
      }
     
      axiosRole.put(API_URLS.ROLE.UPDATE_ROLE,body)
    .then((res)=>{
        if(res.status===201){
          
        toast.info("Role Updated Successfully", {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 500,
          });
        }
        navigate(ROUTE_PATH.ROLE_USER);
    }).catch((err)=>{
      toast.error(err?.response?.data?.statusMessage, {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 500,
      });
    })
  }
  };
  useEffect(()=>{
    getPermissions(location.state["ROLE_DATA"].id)
  },[])
  const handleKeyPress = (event) => {
    if (event.keyCode === 13 || event.which === 13) {
     submitRole();
    }
  }
  return (
    <>
     <Breadcrumb previous='Role/User Management' current='Edit Role' onclick={()=>{navigate(ROUTE_PATH.ROLE_USER)}}/>
    <div className="border-5 shadow rounded-4 create-role-container ">
      <div className="container ms-2 pt-2">
        <div className="row position-relative">
          <div className="col-md-5 mt-2">
            <p>Role Name <span className="textLabelRed">*</span></p>
            <input
              value={formData.role_name}
              className="form-control"
              onChange={(e) => handleChange(e)}
              name="role_name"
              onKeyDown={handleKeyPress}
            ></input>
          </div>
        </div>
        {error?.role_name && (
            <p className="text-danger pt-1 position-absolute">{error?.role_name}</p>
          )}
        <div className="row position-relative">
          <div className="col-md-8 mt-4">
            <p>Role Description <span className="textLabelRed">*</span></p>
            <textarea
             value={formData.role_description}
              class="form-control"
              rows="2"
              onChange={(e) => handleChange(e)}
              name="role_description"
              onKeyDown={handleKeyPress}
            ></textarea>
          </div>
        </div>
        {error?.role_description && (
            <p className="text-danger pt-1 position-absolute">{error?.role_description}</p>
          )}
        <div className="row mt-2">
          <div className="col-md-8 pt-3">
            <p className="">Access</p>
            <table className="table-responsive table table-bordered  mt-3 ">
              <thead className="thead-style ">
                <tr className='tableRowTextColor'>
                  <th>Access On</th>
                  <th>ALL</th>
                  <th>Create</th>
                  <th>Edit</th>
                  <th> View</th>
                  <th>Download</th>
                  <th>Delete</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Communication Timeline</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[0]["moduleAll"]}
                      onChange={() => handleCheckboxChange("0", "moduleAll")}
                      onKeyDown={handleKeyPress}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      disabled
                      checked={true}
                      onChange={() => handleCheckboxChange("0", "moduleCreate")}
                      onKeyDown={handleKeyPress}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      disabled
                      checked={true}
                      onChange={() => handleCheckboxChange("0", "moduleEdit")}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[0]["moduleView"]}
                      onChange={() => handleCheckboxChange("0", "moduleView")}
                      onKeyDown={handleKeyPress}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[0]["moduleDownload"]}
                      onKeyDown={handleKeyPress}
                      onChange={() =>
                        handleCheckboxChange("0", "moduleDownload")
                      
                      }
                    />
                  </td>
                  <td>
                      <input
                        type="checkbox"
                        disabled
                        checked={true}
                        onChange={() =>
                          handleCheckboxChange("0", "moduleDelete")
                        }
                      />
                    </td>
                </tr>
                <tr>
                  <td>Role Management</td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[1]["moduleAll"]}
                      onKeyDown={handleKeyPress}
                      onChange={() => handleCheckboxChange("1", "moduleAll")}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[1]["moduleCreate"]}
                      onChange={() => handleCheckboxChange("1", "moduleCreate")}
                      onKeyDown={handleKeyPress}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[1]["moduleEdit"]}
                      onKeyDown={handleKeyPress}
                      onChange={() => handleCheckboxChange("1", "moduleEdit")}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      checked={permissions[1]["moduleView"]}
                      onKeyDown={handleKeyPress}
                      onChange={() => handleCheckboxChange("1", "moduleView")}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      disabled
                      checked={true}
                      onChange={() =>
                        handleCheckboxChange("1", "moduleDownload")
                      }
                    />
                  </td>
                  <td>
                      <input
                        type="checkbox"
                        checked={permissions[1]["moduleDelete"]}
                        onChange={() =>
                          handleCheckboxChange("1", "moduleDelete")
                        }
                      />
                    </td>
                </tr>
                <tr>
                  <td>User</td>
                  <td>
                    <input
                      type="checkbox"
                      onKeyDown={handleKeyPress}
                      checked={permissions[2]["moduleAll"]}
                      onChange={() => handleCheckboxChange("2", "moduleAll")}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      onKeyDown={handleKeyPress}
                      checked={permissions[2]["moduleCreate"]}
                      onChange={() => handleCheckboxChange("2", "moduleCreate")}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      onKeyDown={handleKeyPress}
                      checked={permissions[2]["moduleEdit"]}
                      onChange={() => handleCheckboxChange("2", "moduleEdit")}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      onKeyDown={handleKeyPress}
                      checked={permissions[2]["moduleView"]}
                      onChange={() => handleCheckboxChange("2", "moduleView")}
                    />
                  </td>
                  <td>
                    <input
                      type="checkbox"
                      disabled
                      checked={true}
                      onChange={() =>
                        handleCheckboxChange("2", "moduleDownload")
                      }
                      onKeyDown={handleKeyPress}
                    />
                  </td>
                  <td>
                      <input
                        type="checkbox"
                        checked={permissions[2]["moduleDelete"]}
                        onChange={() =>
                          handleCheckboxChange("2", "moduleDelete")
                        }
                      />
                    </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-md-2">
            <Button
              className="create-button mt-3 mb-2"
              onClick={() => submitRole()}
            >
              Update Role
            </Button>
          </div>
          <div className="col-md-3">
            <Button className="cancel-button mt-3 mb-2" onClick={()=>{navigate(ROUTE_PATH.ROLE_USER)}}>Cancel</Button>
          </div>
        </div>
      </div>
      
    </div>
    </>
  );
};
export default EditRole;
