import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "../../styles/RoleUser/roleUser.scss";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import CommonTable from "../../common/Table";
import RoleListing from "./RoleListing";
import UserListing from "./UserListing";
import { useLocation, useNavigate } from "react-router";
import { ROUTE_PATH } from "../../constants/Routes";
import Breadcrumb from "../../common/BreadCrumb";
import { useSelector } from "react-redux";

const RoleAndUser = () => {
  const location = useLocation();
  const [key, setKey] = useState(
    location?.state && location?.state["TAB"] ? "user" : "role"
  );
  const navigate = useNavigate();
  const selectLoginData = useSelector((state) => state?.login?.loginData);
  const permissionDTOList = selectLoginData?.permissionDTOList || [];
  const permission = permissionDTOList?.reduce((acc, permission) => {
    const { moduleId, ...rest } = permission;
    acc[moduleId] = rest;
    return acc;
  }, {});

  const renderButton = () => {
    return (
      <>
        {key === "role" ? (
          permission?.["2"]?.moduleCreate && (
            <Button
              className="create-button mt-3 me-2"
              onClick={() => {
                navigate(ROUTE_PATH.ROLE_USER_CREATE_ROLE);
              }}
            >
              Create Role
            </Button>
          )
        ) : (
          permission?.["3"]?.moduleCreate &&
          <Button
            className="create-button mt-3 me-2 "
            onClick={() => {
              navigate(ROUTE_PATH.ROLE_USER_CREATE_USER);
            }}
          >
            Add User
          </Button>
        )}
      </>
    );
  };

  return (
    <>
      <p className="header ">Role / User Management</p>
      <div className="border-5 shadow rounded role-user-container ">
        <div className="d-flex justify-content-between ms-2">
          <div>
            <Tabs
              activeKey={key}
              onSelect={(key) => setKey(key)}
              id="uncontrolled-tab-example"
              className="pt-3"
            >
              <Tab eventKey="role" title="Role" className="nav-link"></Tab>
              <Tab eventKey="user" title="User" className="nav-link"></Tab>
            </Tabs>
          </div>
          {renderButton()}
        </div>
        {
  key === "role" &&
  (permission?.["2"]?.moduleView ||
    permission?.["2"]?.moduleEdit ||
    permission?.["2"]?.moduleDelete) ? (
    <RoleListing />
  ) : key === "user" &&
    (permission?.["3"]?.moduleView ||
      permission?.["3"]?.moduleEdit ||
      permission?.["3"]?.moduleDelete) ? (
    <UserListing />
  ) : (
    <div className="text-center">Data Not found</div>
  )
}

      </div>
    </>
  );
};
export default RoleAndUser;
