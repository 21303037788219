import React from "react";
import style from "../styles/Loader/loader.module.scss";

const Loader = ({ customstyle }) => {
  const mainClassName = customstyle ? style.inlineLoader : style.main;
  return (
    <>
      <div className={mainClassName}>
        <div className="spinner-border text-info" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    </>
  );
};

export default Loader;
