import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import moment from "moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);


const LineChart = ({ graphData, lineFilterMonthValue }) => {
  const {error, success, total} = graphData
  let labels = "";
  if (lineFilterMonthValue === "Week") {
    labels = total?.map((item) => {
      return item?.label ? `Week ${item?.label}` : "";
    }) ?? [];
  } else if (lineFilterMonthValue === "Month") {
    labels = total?.map((item) => {
      return item?.label ? moment(item?.label).format("MMM") : "";
    }) ?? [];
  } else if (lineFilterMonthValue === "Year") {
    labels = total?.map((item) => {
      return item?.label ?? ""
    }) ?? [];
  }

  const DataFormater = (number) => {
    if (number > 1000000000) {
      return (number / 1000000000).toString() + "B";
    } else if (number > 1000000) {
      return (number / 1000000).toString() + "M";
    } else if (number > 1000) {
      return (number / 1000).toString() + "K";
    } else {
      return number.toString();
    }
  };

  const data = {
    labels,
    datasets: [
      {
        label: 'Total',
        data: total?.map((item) => {return item.count}),
        borderColor: '#1A8400',
        backgroundColor: '#FFFFFF',
        borderWidth: 1,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 10,
        formatter: function(context) {
          return context / 1000 + "k";
        }
      },
      {
        label: 'Success',
        data:  success?.map((item) => {return item.count}),
        borderColor: '#2AB4E8',
        backgroundColor: '#FFFFFF',
        borderWidth: 1,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 10,
        formatter: function(context) {
          return context / 1000 + "k";
        }
      },
      {
        label: 'Error',
        data:  error?.map((item) => {return item.count}),
        borderColor: '#FF4343',
        backgroundColor: '#FFFFFF',
        borderWidth: 1,
        pointStyle: 'circle',
        pointRadius: 5,
        pointHoverRadius: 10,
        formatter: function(context) {
          return context / 1000 + "k";
        }
        
      },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
        display: false,
        labels: {
          usePointStyle: true,
          boxWidth: 15,
          font: {
            size: 9
        }
        }
      },
      title: {
        display: false,
        text: 'Chart.js Line Chart',

      },
    },
    scales: {
      x: {
        ticks: {
          color: 'black'
        },
        grid: {
          display: false, // Set to false to hide horizontal grid lines
        },
        display: true,
        title: {
          display: true,
          // text: 'Month',
          color:'black',
          family:'Open sans'
        }
      },
      y: {
        ticks: {
          color: 'black',
          callback: function (value, index, values) {
            return DataFormater(value);
          },
        },
        display: true,
        title: {
          display: true,
          // text: 'Count',
          family:'Open sans',
          color:'black',
        }
      }
    }
  };
  
  
  
  return <Line options={options} data={data} height={200} width={300}  />;
  
}
export default LineChart;

  

