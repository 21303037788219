import { API_URLS } from "../../utils/Constant";
// services/timelineCommunication/index.js
import Axios from "../../utils/axios";

// Updated function to accept query parameters
export const getSmsDetails = async (queryParams) => {
  try {
    const response = await Axios.get(
      API_URLS.COMMUNICATION_TIMELINE.SMS_DETAILS,
      {
        params: queryParams,
      }
    );

    if (response.status===200) {
      const data = response?.data?.data;
      const totalCount = response?.data?.totalCount
      return {data,totalCount};
    } else {
      return null
      throw new Error(response.statusMessage);
    }
  } catch (error) {
    return null;
  }
};
