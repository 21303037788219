export const ROUTE_PATH = {
    COMMUNICATION_TIMELINE: "/communication-timeline",
    STATISTICS:"/statistics",
    PROFILE:"/profile",
    ROLE_USER:"/roleuser",
    ROLE_USER_CREATE_ROLE:"/roleuser/create-role",
    ROLE_USER_EDIT_ROLE:"/roleuser/edit-role",
    ROLE_USER_CREATE_USER:"/roleuser/create-user",
    ROLE_USER_EDIT_USER:"/roleuser/edit-user",
    SSO:'/sucess',
    LOGIN:"/"
    
};
