import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, Chart } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

const DoughnutChart = (props) => {
  const { totalDeliveredCount, totalErrorCount, totalOpportunities} = props.data;
  const data = {
    labels: ["Success", "Error"],
    datasets: [
      {
        label: "Opportunities",
        data: [totalDeliveredCount, totalErrorCount],
        backgroundColor: ["#6BD1A4", "#EB6D59"],
        borderColor: ["#6BD1A4", "#EB6D59"],
        borderWidth: 1,
        hoverBorderWidth: 8,
        offset: 30,
        borderRadius: 4,
      },
    ],
    plugins: {
      labels: {
        render: "percentage",
        fontColor: ["green", "white", "red"],
        precision: 2
      }
    },
  };
  const option = {
    responsive: true,
          maintainAspectRatio: true,
  }

  return (
    <>
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>

      <Doughnut data={data} options={option}/>
      <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', textAlign: 'center' }}>
        <div>Total</div><div> Opportunities</div>
        <b>{totalOpportunities}</b>
      </div>
        </div>
    </>
  );
};
export default DoughnutChart;
