
import '../../styles/Login/login.scss';
import React from "react";
import { ReactComponent as Loginimg } from "../../images/loginbg.svg";
import { ReactComponent as TATALOGO }   from "../../images/TATALOGO.svg";
import { ReactComponent as Outlooklogo }   from "../../images/outlooklogo.svg";
const Login =()=>{
    const handleLogin = ()=>{ 
        window.location.assign("https://communicationportal.tatacapital.com:8084/auth-service/oauth2/authorization/azure")
    }
    return(
        <>
        <div className="login-container">
            <div className="container">
            <div className="d-flex justify-content-evenly">
                <div className="d-flex justify-content-center w-50 login-img ">
                   <Loginimg className=""></Loginimg>
                </div>
                <div className=" d-flex align-items-center">
                    <div className="login-box p-5">
                        <TATALOGO/>
                        <div className="d-flex flex-column pt-5 align-items-center">
                        <p>Sign in with Outlook</p>
                        <button className="outlook-btn btn  w-100 mt-3 mb-3"  onClick={handleLogin}><Outlooklogo className="mb-1 me-2" /> OUTLOOK</button>
                        </div>
                                
                    </div>
                </div>
            </div>
        </div>
        </div>
        </>
    );
}
export default Login;