import React, { useState } from "react";
import styles from "../styles/dashboardNavigation/dashboardNavigation.module.scss";

const DashboardNavigation = ({ children, onTabChange }) => {
  const [activeTab, setActiveTab] = useState(children[0]?.props.label);

  const changeTab = (tab) => {
    setActiveTab(tab);
    // Call the callback function to notify the parent component about the tab change
    onTabChange(tab);
  };

  return (
    <div className={styles.tabs}>
      <div className={styles.tabButtons}>
        {children.map(({ props: { label } }) => (
          <button
            key={label}
            className={`${label === activeTab ? styles.active : ""} ${
              label === "WhatsApp" || label === "Post" ? styles.disabled : ""
            }`}
            onClick={() => changeTab(label)}
            disabled={label === "WhatsApp" || label === "Post"}
          >
            {label}
          </button>
        ))}
      </div>

      <div className={styles.tabContent}>
        {
          children.find(({ props: { label } }) => label === activeTab)?.props
            .children
        }
      </div>
    </div>
  );
};

export default DashboardNavigation;
