import React from "react";
import { Link } from "react-router-dom";
import style from "../styles/CustomButton/CustomButton.module.scss";
import { ROUTE_PATH } from "../constants/Routes"; // Make sure to import ROUTE_PATH

const CustomButton = ({ buttonName, onClick}) => {
  // const routePath =
    // buttonName === "Add User" ? ROUTE_PATH.CREATE_USER_DETAIL : ROUTE_PATH. CREATE_ROLE_DETAIL;

  return (
    // <Link to={routePath}>
      <button className={style.customButtom} onClick={onClick}> 
        {buttonName}
      </button>
    //  </Link>
  );
};

export default CustomButton;
