import React, { useState, useEffect } from "react";
import SearchImage from "../images/Search icon blue.svg";
import styles from "../styles/SearchBox/searchBox.module.scss";

const SearchBox = ({
  placeHolder,
  onSearchChange,
  errorMessage,
  inputtype,
  handleKey,
  isPan,
  isClicked
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");
  useEffect(() => {
    setInputValue("");
  }, [isClicked]);

  
  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  const handleChange = (event) => {
    const newValue = event.target.value;
    setInputValue(newValue);
    onSearchChange(newValue);
  };
  return (
    <div className={styles.container}>
      {/* <div className={`${styles.main} ${errorMessage ? styles.error : ''}`}> */}
      <div className={`${styles.main} ${errorMessage ? styles.errorOutline : ""}`}>
        <input
          type={inputtype}
          placeholder={placeHolder}
          // className={`${styles.inputField} ${errorMessage ? styles.errorInput : ''}`}
          className={`${styles.inputField}`}
          value={isPan?inputValue.toUpperCase():inputValue}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={handleKey}
        />
        {!isFocused && (
          <img
            src={SearchImage}
            alt="SearchImage"
            className={styles.searchImage}
          />
        )}
      </div>
     {errorMessage && <p className={styles.errorMessage}>{errorMessage}</p>}
    </div>
  );
};

export default SearchBox;
