import "../../styles/RoleUser/createRole.scss";
import { useState } from "react";
import { Button } from "react-bootstrap";
import moment from "moment/moment";
import axiosRole from '../../utils/axiosRole';
import { API_URLS, REGX } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router";
import { ROUTE_PATH } from "../../constants/Routes";
import Breadcrumb from "../../common/BreadCrumb";


const CreateRole = () => {
  const navigate = useNavigate();

  const [permissions, setPermissions] = useState([
    {
      moduleId: 1,
      moduleCreate: true,
      moduleEdit: true,
      moduleView: false,
      moduleDownload: false,
      moduleAll: false,
      moduleDownload: false,
      moduleDelete:true,
      createdAt: moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
      createdBy: "Admin",
    },
    {
      moduleId: 2,
      moduleCreate: false,
      moduleEdit: false,
      moduleView: false,
      moduleDownload: true,
      moduleAll: false,
      approval: false,
      moduleDownload: false,
      moduleDelete:false,
      createdAt: moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
      createdBy: "Admin",
    },
    {
      moduleId: 3,
      moduleCreate: false,
      moduleEdit: false,
      moduleView: false,
      moduleDownload: true,
      moduleAll: false,
      moduleDownload: false,
      moduleDelete:false,
      createdAt: moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
      createdBy: "Admin",
    },
  ]);
  const [formData, setFormData] = useState({
    role_name: null,
    role_description: null,
    createdAt: moment.utc(new Date()).format("YYYY-MM-DD HH:mm:ss.SSS"),
    createdBy: "Admin",
    permissionDTO: permissions,
  });
  const [error, setError] = useState(null);

  const handleCheckboxChange = (module, permission) => {
    permissions[module][permission] = !permissions[module][permission];
    const modules = Object.keys(permissions[module]);
    const access = Object.values(permissions[module]);
    if (permission === "moduleAll") {
      access.map((i, j) => {
        if (typeof i === "boolean") {
          permissions[module][modules[j]] = permissions[module][permission];
        }
        return i;
      });
    }else{
     
      const allchecked =["moduleCreate","moduleDownload","moduleView","moduleEdit"].every((i) => {
         return permissions[module][i] === true
      });
     
      permissions[module].moduleAll = allchecked;
    }
    const updatedPermissions = [...permissions];
    setPermissions(updatedPermissions);
  };
  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  const validateData = () => {
    const newErrors = {};
    let isvalid = true;
    if (!formData?.role_name || !REGX.SPECIAL_CHAR.test(formData?.role_name) || !REGX.COMMA.test(formData?.role_name)) {
      isvalid = false;
      newErrors.role_name = "Please Enter Valid Role Name";
    }if (!formData?.role_description) {
      newErrors.role_description = "Role Description is required";
      isvalid = false;
    }else if (!REGX.ROLE_DESC.test(formData?.role_description)) {
      newErrors.role_description = "Role Description cannot contain special characters";
      isvalid = false;
  }
    setError(newErrors);
    return isvalid;
  };
  const submitRole = () => {

    if (validateData()) {
      axiosRole.post(API_URLS.ROLE.CREATE_ROLE, formData)
        .then((res) => {
          if (res.status === 201) {
            toast.info("Role Created Successfully", {
              position: toast.POSITION.BOTTOM_RIGHT,
              autoClose: 500,
            });
            navigate(ROUTE_PATH.ROLE_USER);
          }
        })
        .catch((err) => {
          console.error("Error",err);
          toast.error(err?.response?.data?.statusMessage, {
            position: toast.POSITION.BOTTOM_RIGHT,
            autoClose: 500,
          });
        });
    }
  };


  const handleKeyPress = (event) => {
  
  
    if (event.keyCode === 13 || event.which === 13) {
     submitRole();
    }
  }
  return (
    <>
      <Breadcrumb
        previous="Role/User Management"
        current="Create Role"
        onclick={() => {
          navigate(ROUTE_PATH.ROLE_USER);
        }}
      />
      <div className="border-5 shadow rounded-4 create-role-container  ">
        <div className="container ms-2 pt-1">
          <div className="row position-relative ">
            <div className="col-md-5 mt-2">
              <p>
                Role Name<span className="textLabelRed">*</span>
              </p>
              <input
                className="form-control"
                onChange={(e) => handleChange(e)}
                name="role_name"
                onKeyDown={handleKeyPress}
              ></input>
               {error?.role_name && (
            <p className="text-danger pt-1 position-absolute">{error?.role_name}</p>
          )}
            </div>
          </div>
         
          <div className="row position-relative">
            <div className="col-md-8 mt-4">
              <p>
                Role Description<span className="textLabelRed">*</span>
              </p>
              <textarea
                class="form-control"
                rows="2"
                onChange={(e) => handleChange(e)}
                name="role_description"
                onKeyDown={handleKeyPress}
            
              ></textarea>
          {error?.role_description && (
            <p className="text-danger pt-1 position-absolute" >{error?.role_description}</p>
          )}
            </div>
          </div>
          <div className="row position-relative">
            <div className="col-md-8 mt-4">
              <p className="">
                Access <span className="textLabelRed">*</span>
              </p>
              <table className="table-responsive table table-bordered  mt-2">
                <thead className="thead-style ">
                  <tr className='tableRowTextColor'>
                    <th>Access On</th>
                    <th>ALL</th>
                    <th>Create</th>
                    <th>Edit</th>
                    <th> View</th>
                    <th>Download</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Communication Timeline</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[0]["moduleAll"]}
                        onChange={() => handleCheckboxChange("0", "moduleAll")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        disabled
                        checked={true}
                        onChange={() =>
                          handleCheckboxChange("0", "moduleCreate")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        disabled
                        checked={true}
                        onChange={() => handleCheckboxChange("0", "moduleEdit")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[0]["moduleView"]}
                        onChange={() => handleCheckboxChange("0", "moduleView")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[0]["moduleDownload"]}
                        onChange={() =>
                          handleCheckboxChange("0", "moduleDownload")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={true}
                        disabled
                        onChange={() =>
                          handleCheckboxChange("0", "moduleDownload")
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>Role Management</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[1]["moduleAll"]}
                        onChange={() => handleCheckboxChange("1", "moduleAll")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[1]["moduleCreate"]}
                        onChange={() =>
                          handleCheckboxChange("1", "moduleCreate")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[1]["moduleEdit"]}
                        onChange={() => handleCheckboxChange("1", "moduleEdit")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[1]["moduleView"]}
                        onChange={() => handleCheckboxChange("1", "moduleView")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        disabled
                        checked={true}
                        onChange={() =>
                          handleCheckboxChange("1", "moduleDownload")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[1]["moduleDownload"]}
                        onChange={() =>
                          handleCheckboxChange("1", "moduleDownload")
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>User</td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[2]["moduleAll"]}
                        onChange={() => handleCheckboxChange("2", "moduleAll")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[2]["moduleCreate"]}
                        onChange={() =>
                          handleCheckboxChange("2", "moduleCreate")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[2]["moduleEdit"]}
                        onChange={() => handleCheckboxChange("2", "moduleEdit")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[2]["moduleView"]}
                        onChange={() => handleCheckboxChange("2", "moduleView")}
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        disabled
                        checked={true}
                        onChange={() =>
                          handleCheckboxChange("2", "moduleDownload")
                        }
                      />
                    </td>
                    <td>
                      <input
                        type="checkbox"
                        checked={permissions[2]["moduleDownload"]}
                        onChange={() =>
                          handleCheckboxChange("2", "moduleDownload")
                        }
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row">
            <div className="col-md-2">
              <Button
              
                className="create-button mt-3 mb-2"
                onClick={() => submitRole()}
              >
                Create Role
              </Button>
            </div>
            <div className="col-md-3">
              <Button
                className="cancel-button mt-3 mb-2"
                onClick={() => {
                  navigate(ROUTE_PATH.ROLE_USER);
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
};
export default CreateRole;
