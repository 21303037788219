import { Button } from "react-bootstrap";
import "../../styles/RoleUser/createUser.scss";
import { ROUTE_PATH } from "../../constants/Routes";
import { useLocation, useNavigate } from "react-router";
import SingleDropDown from "../../common/SingleDropdown";
import { useEffect, useState } from "react";
import AxiosRole from "../../utils/axiosRole";
import { API_URLS, REGX, STATUS_CODE } from "../../utils/Constant";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment/moment";
import Breadcrumb from "../../common/BreadCrumb";
const EditUser = () => {
  const navigate = useNavigate();
  const location=useLocation();
  const [selectedValue, setSelectedValue] = useState(location.state['USER_DATA'].roleId);
  const [options, setOptions] = useState(null);
  const [error, setError] = useState(null);

  const [formData,setFormData]=useState(
    {
    userId:location.state['USER_DATA'].id,
    userName:location.state['USER_DATA'].userName,
    domainId:location.state['USER_DATA'].domainId,
    emailAddress:location.state['USER_DATA'].emailAddress,
    roleId:location.state['USER_DATA'].roleId,
    contactNo:location.state['USER_DATA'].contactNo,
    description:location.state['USER_DATA'].description,
    updatedAt:moment.utc(new Date()).format('YYYY-MM-DD HH:mm:ss.SSS'),
    updatedBy:"Admin"
  })
  const handleSelectChange = (event) => {
    setFormData({...formData,roleId: +event.target.value})
    setSelectedValue(event.target.value);
  };
  const validateData = () => {
    const newErrors = {};
    let isvalid = true;
    if (!formData.userName || !REGX.USER_NAME.test(formData.userName)) {
      isvalid = false;
      newErrors.userName = "Please Enter valid User Name ";
    } if (!formData.domainId || !REGX.NUMBERS_ONLY.test(formData.domainId)) {
      newErrors.domainId = "Please Enter valid Domain ID ";
      isvalid = false;
    } if (!formData.emailAddress || !REGX.EMAIL_REGX.test(formData.emailAddress)) {
      newErrors.emailAddress = "Please enter valid Email ID ";
      isvalid = false;
    }if (!formData.roleId) {
      newErrors.roleId = "Role is required";
      isvalid = false;
    } if (!formData.contactNo || !REGX.CONTACTNUMBER.test(formData.contactNo)) {
      newErrors.contactNo = "Please enter valid Contact No";
      isvalid = false;
    } if (!formData.description) {
      newErrors.description = "Description is required";
      isvalid = false;
    }else if (!REGX.ROLE_DESC.test(formData?.description)) {
      newErrors.description= "User Description cannot contain special characters";
      isvalid = false;
  }
    setError(newErrors);
    return isvalid;
  };
  const submitUser = () => {
    if(validateData()){
    AxiosRole.post(API_URLS.USER.UPDATE_USER,formData)
    .then((res)=>{
        if(res.status === 200){
            toast.info("User Updated Successfully", {
                position: toast.POSITION.BOTTOM_RIGHT,
                autoClose: 500,
              });
            navigate(ROUTE_PATH.ROLE_USER, { state: { TAB: "USER" } });
        }
    }).catch((err)=>{
        console.error("Error e",err);
        toast.error(err?.response?.data?.statusMessage, {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 500,
        });
    })
  }
  };
  
  const getRole = () => {
    AxiosRole.get(API_URLS.ROLE.ROLE_LIST)
      .then((res) => {
      
        let result = res?.data.data;
        result = result.map((item) => {
          let newItem = {
            key: item.role_id,
            value: item.role_name,
          };
          return newItem;
        });
        const matchingRole = result?.find((item) => item.key === location.state['USER_DATA'].roleId);
        const remainingOfferOptions =result?.filter((item) => item.key !== location.state['USER_DATA'].roleId);
        const RoleOptions = matchingRole ? [matchingRole, ...remainingOfferOptions] : result;
        setOptions([...RoleOptions]);
      })
      .catch((err) => {
        console.error("Error  ", err);
      });
  };
  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };
  useEffect(() => {
    getRole();
  }, []);
  
  const handleKeyPress = (event) => {
    
    if (event.keyCode === 13 || event.which === 13) {
     submitUser();
    }
  }
  return (
    <>
      <Breadcrumb
        previous="Role/User Management"
        current="Edit User"
        onclick={() => {
          navigate(ROUTE_PATH.ROLE_USER, { state: { TAB: "USER" } });
        }}
      />
      <div className=" border-5 shadow rounded-4 create-user-container ">
        <div className="container ms-2 pt-1">
          <div className="row position-relative ">
            <div className="col-md-5 mt-4">
              <p>
                User Name<span className="textLabelRed">*</span>
              </p>
              <input
               onKeyDown={handleKeyPress}
                className="form-control"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={formData?.userName}
                name="userName"
              />
              {error?.userName && (
                <p className="text-danger pt-1 position-absolute">{error?.userName}</p>
              )}
            </div>
            <div className="col-md-5 mt-4">
              <p>
                Domain ID<span className="textLabelRed">*</span>
              </p>
              <input
                className="form-control"
                onKeyDown={handleKeyPress}
                onChange={(e) => {
                  handleChange(e);
                }}
                name="domainId"
                value={formData?.domainId}
              />
              {error?.domainId && (
                <p className="text-danger pt-1 position-absolute">{error?.domainId}</p>
              )}
            </div>
          </div>
          <div className="row position-relative">
            <div className="col-md-5 mt-4">
              <p>
                Email ID<span className="textLabelRed">*</span>
              </p>
              <input
               onKeyDown={handleKeyPress}
                className="form-control"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="emailAddress"
                value={formData?.emailAddress}
              />
              {error?.emailAddress && (
                <p className="text-danger pt-1 position-absolute">{error?.emailAddress}</p>
              )}
            </div>

            <div className="col-md-5 mt-4">
              <p>
                Contact No<span className="textLabelRed">*</span>
              </p>
              <input
               onKeyDown={handleKeyPress}
                className="form-control"
                onChange={(e) => {
                  handleChange(e);
                }}
                name="contactNo"
                value={formData.contactNo}
              />
              {error?.contactNo && (
                <p className="text-danger pt-1 position-absolute">{error?.contactNo}</p>
              )}
            </div>
          </div>
          <div className="row position-relative">
            <div className="col-md-5 mt-4">
              <p>
                Role<span className="textLabelRed">*</span>
              </p>
              <SingleDropDown
                options={options}
                onSelectChange={handleSelectChange}
                selectedValue={selectedValue}
                placeholder="Select Role"
              />
              {error?.roleId && (
                <p className="text-danger pt-1 position-absolute">{error?.roleId}</p>
              )}
            </div>
          </div>
          <div className="row mt-4 position-relative ">
            <div className="col-md-10">
              <p>
                User Description<span className="textLabelRed">*</span>
              </p>
              <textarea
               onKeyDown={handleKeyPress}
                class="form-control"
                rows="2"
                name="description"
                onChange={(e) => {
                  handleChange(e);
                }}
                value={formData?.description}

              ></textarea>
              {error?.description && (
                <p className="text-danger pt-1 position-absolute">{error?.description}</p>
              )}
            </div>
          </div>
          <div className="row mt-5">
            <div className="col-md-2">
              <Button
                className="create-button  mb-3"
                onClick={() => submitUser()}
              >
                Update User
              </Button>
            </div>
            <div className="col-md-3">
              <Button
                className="cancel-button mb-3"
                onClick={() => {
                  navigate(ROUTE_PATH.ROLE_USER, { state: { TAB: "USER" } });
                }}
              >
                Cancel
              </Button>
            </div>
          </div>
        </div>
       
      </div>
    </>
  );
};
export default EditUser;
