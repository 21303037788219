import Axios from "../../utils/axios";
import { API_URLS } from "../../utils/Constant";

export const getUserDetails = async (queryParams) => {
  const defaultData = [];

  try {
    const response = await Axios.get(
      API_URLS.COMMUNICATION_TIMELINE.USER_DETAILS,
      {
        params: queryParams,
      }
    );
    if (response.status===200) {
      const data = response?.data?.data;
    
      return data;
    } else {
      return null;
      throw new Error(response.statusMessage);
    }
  } catch (error) {
    return null;
    throw error;
  }
};
