// TextBox.js

import React from 'react';
import { colors } from '../constants/colors/colors';
const TextBox = ({ label, value, onChange,disabled }) => {
  const labelStyle = {
    top: '139px',
    left: '95px',
    // width: '81px',
    height: '19px',
    textAlign: 'left',
    font: 'normal normal 600 14px/21px Open Sans black',
    letterSpacing: '0px',
    opacity: '1',
  };

  const inputStyle = {
    top: '10px',
    left: '0px',
    width: '440px',
    height: '40px',
    background: `${colors.white}`,
    border: `1px solid ${colors.borderGray}`,
    borderRadius: '7px',
    opacity: '1',
    marginLeft:"20px"
  };

  return (
    <div>
      <label style={labelStyle}>{label}</label>
      <input
        type="text"
        value={value}
        onChange={onChange}
        style={inputStyle}
        disabled={disabled }
      />
    </div>
  );
};

export default TextBox;
